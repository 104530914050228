.pageTitle {
  width: 100%;
  height: 5rem;
  font-size: 3rem;
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
