.pageTitle {
  width: 100%;
  height: 5rem;
  font-size: 2rem;
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .content {
  width: 100%;
  height: 100%;
} */

.searchBar {
  width: 100%;
  height: 10rem;
}
