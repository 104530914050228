.flex {
  display: flex;
  justify-content: space-between;
 padding-left: 20px;
}
/* BusinessChooseShopOpenTime.module.css */
.buttonContainer {
  display: flex;
  justify-content: center; /* This will center the buttons */
  gap: 10px; /* Optional: adds space between buttons */
}