@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* For rgb(255 115 179 / <alpha-value>) */
    --color-primary: 255 115 179;

    /* For hsl(198deg 93% 60% / <alpha-value>) */
    --color-primary: 198deg 93% 60%;

    /* For rgba(255, 115, 179, <alpha-value>) */
    --color-primary: 255, 115, 179;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
