.pageTitle {
  /* width: 100%;
  height: 5rem; */
  margin: 1rem;
  font-size: 2rem;
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .content {
  width: 100%;
  height: 100%;
} */

.searchBar {
  width: auto;
  /* height: 10rem; */
}
