.pageTitle {
  width: 100%;
  height: 5rem;
  font-size: 2rem;
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}

/* .content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
} */
